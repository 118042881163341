import React from "react";
import Layout from "../components/Layout/layout";
import SEO from "../components/SEO/seo";
import { LoginPage } from "../components/auth";
const Login = () => (
  <Layout>
    <SEO title="Login" />
    <br />
    <br />
    <br />
    <br />
    <LoginPage />
  </Layout>
);

export default Login;
